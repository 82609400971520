// ErrorPage.js
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = ({ errorMessage, lang }) => {
    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate(`/${lang}`);
    };

    return (
        <div className="error-page">
            <h1>Error</h1>
            <p>{errorMessage || "Ha ocurrido un error inesperado."}</p>
            <button onClick={redirectToHome}>Ir a la Página Principal</button>
        </div>
    );
};

export default ErrorPage;
