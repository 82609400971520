// LanguageWrapper.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import i18n from "../i18n"; // Asegúrate de importar tu configuración i18n

const LanguageWrapper = ({ children }) => {
    const { lang } = useParams();

    useEffect(() => {
        if (lang && i18n.hasResourceBundle(lang, "translation")) {
            i18n.changeLanguage(lang);
        }
    }, [lang]);

    return <>{children}</>;
};

export default LanguageWrapper;
