// gameService.js
async function getGame(send, gameId) {
    const response = await send({
        route: "/game",
        payload: { id: gameId },
    });

    if (
        response &&
        response.payload &&
        response.payload.success &&
        response.payload.data &&
        Object.keys(response.payload.data).length > 0
    ) {
        return response.payload.data;
    } else {
        throw new Error(response.payload?.error || "Error al obtener la partida.");
    }
}

async function startGame(send) {
    await send({
        route: "/start",
        payload: {},
    });
}

async function resetGame(send, gameId) {
    await send({
        route: "/reset",
        payload: {
            id: gameId,
        },
    });
}

const gameService = {
    getGame,
    startGame,
    resetGame,
};

export default gameService;
