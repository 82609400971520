import React, { useState, useEffect } from "react";
import "./Play.css";
import PlayerPortrait from "./PlayerPortrait";
import OthersPortrait from "./OthersPortrait";

import phaseService from "../services/phase";

import Voting from "./Voting";
import Doors from "./Doors";
import { useTranslation } from "react-i18next";

function Play({ game, player, lang, send, sid, pendingVotings, othersKnownInfo, allowToExploreConfig, items }) {
    const { t } = useTranslation();
    const [selectedVoting, setSelectedVoting] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(game.phase?.endAt));
    const [showContinueButton, setShowContinueButton] = useState(true);

    const isDead = player?.states?.includes("dead");

    useEffect(() => {
        const timer = setInterval(() => {
            if (game.phase && game.phase.endAt) {
                setTimeLeft(calculateTimeLeft(game.phase?.endAt));
            }
        }, 1000);
        setShowContinueButton(true);
        return () => clearInterval(timer);
    }, [game.phase, game.phase?.endAt]);

    const handleMovePhase = () => {
        // Lógica para manejar el cambio de fase
        const success = phaseService.movePhase(send, game?.id);
        if (success) {
            setShowContinueButton(false);
        }
    };

    const handleVotingSelect = (voting) => {
        setSelectedVoting(voting);
    };

    const handleBackToVotings = () => {
        setSelectedVoting(null);
    };

    const handleBackToRoom = () => {
        setSelectedRoom(null);
    };

    const allowToExplore =
        allowToExploreConfig &&
        allowToExploreConfig.allowed &&
        game?.phase?.type === allowToExploreConfig.phase.type &&
        game?.phase?.turn === allowToExploreConfig?.phase?.turn;

    const availableKeys = allowToExplore && allowToExploreConfig?.keys ? allowToExploreConfig?.keys || 0 : 0;

    function calculateTimeLeft(endAt) {
        const difference = +new Date(endAt) - +new Date();
        return Math.max(0, Math.floor(difference / 1000));
    }

    function computeRoomStatus(room) {
        let closeDoors = room.to - room.from + 1;
        let openDoors = 0;
        let treasures = 0;
        for (const door of game.openDoors) {
            if (door.number < room.from || door.number > room.to) {
                continue;
            }
            if (door.treasureFound) {
                treasures++;
            } else {
                openDoors++;
            }
            closeDoors--;
        }
        room.doorCloseCount = closeDoors;
        room.doorOpenCount = openDoors;
        room.itemFoundCount = treasures;
    }

    const renderRoomSelection = () => {
        return (
            <div className="room-selection-container">
                <h2>{t(`default-list-room-title`)}</h2>
                {(game?.rooms || [])
                    .map((r) => {
                        computeRoomStatus(r);
                        return r;
                    })
                    .map((room, index) => (
                        <button key={index} onClick={() => setSelectedRoom(room)} className={room.type}>
                            <div className="icon-container">
                                <div className="door-icon close">
                                    <span>{room.doorCloseCount}</span>
                                </div>

                                <div className="door-icon open">
                                    <span>{room.doorOpenCount}</span>
                                </div>

                                <div className="door-icon item-found">
                                    <span>{room.itemFoundCount}</span>
                                </div>
                            </div>
                        </button>
                    ))}
            </div>
        );
    };

    const renderVotingOrDoors = () => {
        if (selectedVoting) {
            return (
                <Voting
                    selectedVoting={selectedVoting}
                    pendingVotings={pendingVotings}
                    handleBackToVotings={handleBackToVotings}
                    game={game}
                    player={player}
                    lang={lang}
                    send={send}
                    sid={sid}
                />
            );
        } else if (allowToExplore && selectedRoom) {
            return (
                <Doors
                    roomType={selectedRoom.type}
                    fromRoom={selectedRoom.from}
                    toRoom={selectedRoom.to}
                    keys={availableKeys}
                    openedDoors={game?.openDoors || []}
                    gameId={game.id}
                    handleBackToRoom={handleBackToRoom}
                    send={send}
                />
            );
        } else {
            return (
                <>
                    {pendingVotings?.length > 0 && (
                        <div className="voting-list-container">
                            {pendingVotings.map((voting) => (
                                <button key={voting.type} onClick={() => handleVotingSelect(voting.type)}>
                                    {t(`default-votation-title-${voting.type}`)}
                                </button>
                            ))}
                        </div>
                    )}
                    {allowToExplore && renderRoomSelection()}
                    <PlayerPortrait
                        player={player}
                        lang={lang}
                        keys={availableKeys}
                        items={items}
                        winners={game?.winners}
                    />
                    <OthersPortrait winners={game?.winners} othersKnownInfo={othersKnownInfo} />
                </>
            );
        }
    };

    return (
        <div className={`play-app-container ${game.status === "finished" ? "winners" : game.phase?.type}`}>
            {/* Temporizador */}
            <div className="timer-container">
                {!isDead && showContinueButton && game.status !== "finished" && (
                    <button className="continue-button" onClick={handleMovePhase}>
                        {t(`default-next-phase`)}
                    </button>
                )}
                <div className={`custom-timer ${timeLeft < 10 ? "timer-low-time" : ""}`}>{timeLeft}</div>
            </div>

            {/* Renderiza Voting, Doors o la interfaz por defecto según el estado */}
            {renderVotingOrDoors()}
        </div>
    );
}

export default Play;
