import React, { useState } from "react";
import "./Doors.css"; // Asegúrate de tener este archivo CSS en la ruta correcta
import exploringService from "../services/exploring";

const DoorsComponent = ({ roomType, fromRoom, toRoom, keys = 2, openedDoors = [], gameId, handleBackToRoom, send }) => {
    const [selectedDoor, setSelectedDoor] = useState(null);

    const handleDoorClick = (doorId) => {
        const isOpened = (openedDoors || []).find((door) => door.number === doorId) !== undefined;
        if (keys > 0 && !isOpened) {
            setSelectedDoor(doorId);
        } else {
            setSelectedDoor(null);
        }
    };

    const handleClose = () => {
        // Luego regresa al componente anterior
        handleBackToRoom();
    };

    const handleExplore = async () => {
        if (!selectedDoor) {
            return;
        }

        if (keys <= 0) {
            return;
        }

        if (openedDoors.find((door) => door.number === selectedDoor) !== undefined) {
            return;
        }

        // Llama a la función explore del servicio exploring.js
        const response = await exploringService.explore(send, gameId, selectedDoor);
        if (response) {
            // TODO: show error if needed
        }
    };

    const renderDoor = (doorId) => {
        // ...
        let doorClass = "door-icon";

        const isDoorOpened = (openedDoors || []).find((door) => door.number === doorId);

        if (isDoorOpened) {
            doorClass += isDoorOpened.treasureFound ? " item-found" : " open";
        } else {
            doorClass += " close";
        }

        // Agregar la clase 'selected' si la puerta está seleccionada
        if (selectedDoor === doorId) {
            doorClass += " selected";
        }

        return <button key={doorId} className={doorClass} onClick={() => handleDoorClick(doorId)}></button>;
    };

    return (
        <div className={`doors-container ${roomType}`}>
            <button className="close-button" onClick={handleClose}>
                &#10005;
            </button>
            <div className="keys-container">
                <div className="key-icon">
                    <div className="keys-count">{keys}</div>
                </div>
            </div>
            <div className="doors-grid">
                {Array.from({ length: toRoom - fromRoom + 1 }, (_, i) => fromRoom + i).map((number) =>
                    renderDoor(number)
                )}
            </div>
            <div className="confirm-button-container">
                <button className={`confirm-button`} onClick={handleExplore}>
                    Confirmar
                </button>
            </div>
        </div>
    );
};

export default DoorsComponent;
