import React from "react";
import "./LobbyPlayersList.css";
import "./RoleImages.css";

const LobbyPlayersList = ({ me, players, isOwner, removePlayer }) => {
    return (
        <div className="lobby-players-list">
            {(players || []).map((player, index) => (
                <div key={index} className="lobby-player">
                    {isOwner && me.id !== player.id && (
                        <button className="remove-player-button" onClick={() => removePlayer(player.id)}>
                            X
                        </button>
                    )}
                    <div className={`other-image  unknown`} alt={`Player ${index}`}></div>
                    <h3 className="other-name">{player?.name}</h3>
                </div>
            ))}
        </div>
    );
};

export default LobbyPlayersList;
