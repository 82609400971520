import React from "react";
import "./PlayerPortrait.css";
import "./RoleImages.css";
import { useTranslation } from "react-i18next";

const PlayerPortrait = ({ player, keys, items, winners }) => {
    const iconStates = ["in_love", "protected", "muted"];

    const { t } = useTranslation();

    const isDead = player?.states?.includes("dead");

    return (
        <div className="player-portrait">
            <div className={`portrait-frame ${winners?.some((winner) => winner.id === player.id) ? "winner" : ""}`}>
                <div className="role-category">
                    <span className="player-role">{t(`default-role-title-${player?.role}`)}</span>
                    <span className="role-category-divider"> - </span>
                    <span className="player-category">{player?.comrades}</span>
                </div>
                <div className={`portrait-image ${isDead ? "dead" : player?.role}`} alt={player?.name}></div>
                <h2 className="player-name">{isDead ? "RIP" : player?.name}</h2>
                {/* Descripción del rol debajo del nombre del jugador */}
                <p className="role-description">{t(`default-role-description-${player?.role}`)}</p>
            </div>
            <div className="state-icons">
                {(player?.states || [])
                    .filter((s) => iconStates.includes(s))
                    .map((state, idx) => (
                        <div key={idx} className={`state-icon ${state}`}></div>
                    ))}
            </div>
            <div className="items-icons">
                {(items || []).map((item, idx) => (
                    <div key={idx} className={`item-icon ${item.type}`}></div>
                ))}
                {keys > 0 && (
                    <div className="items-icon key-icon">
                        <div className="keys-count">{keys}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlayerPortrait;
