// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importa tus archivos de traducción
import translationEN from "./locales/en/translations.json";
import howToPlayEN from "./locales/en/howToPlay.json";
import translationES from "./locales/es/translations.json";
import howToPlayES from "./locales/es/howToPlay.json";

// Merge translations
const resources = {
    en: {
        translation: { ...translationEN, ...howToPlayEN },
    },
    es: {
        translation: { ...translationES, ...howToPlayES },
    },
};

i18n.use(initReactI18next) // pasa i18n a react-i18next
    .init({
        resources,
        fallbackLng: "en", // idioma de respaldo

        interpolation: {
            escapeValue: false, // no necesitas escapar de xss en react
        },
    });

export default i18n;
