import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { FaCopy } from "react-icons/fa";
import LobbyPlayersList from "./LobbyPlayersList";
import "./Lobby.css";

function Lobby({ game, player, lang, send, sid }) {
    const { t } = useTranslation();

    const [newPlayerName, setNewPlayerName] = useState("");
    const [displayInputName, setDisplayInputName] = useState(true);

    const MIN_NUMBER_PLAYERS = 4;

    const isEligibleToStart = game?.players?.length >= MIN_NUMBER_PLAYERS;

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleJoinGame();
        }
    };

    const handleNewPlayerNameChange = (e) => {
        setNewPlayerName(e.target.value);
    };

    const handleJoinGame = async () => {
        if (!isNameTaken) {
            try {
                const response = await send({
                    route: "/addPlayer",
                    payload: {
                        gameId: game.id,
                        name: newPlayerName,
                    },
                });

                if (response && response.payload.success) {
                    setDisplayInputName(false);
                }
            } catch (error) {
                console.error("Error al unirse a la partida:", error);
            }
        }
    };

    const handleRemovePlayer = async (playerId) => {
        try {
            console.log("playerId", playerId);
            const response = await send({
                route: "/removePlayer",
                payload: {
                    gameId: game.id,
                    playerId,
                },
            });

            if (response && response.payload.success) {
                // Navegar a la página del juego, mostrar mensaje, etc.
            }
        } catch (error) {
            console.error("Error al unirse a la partida:", error);
        }
    };

    const handleStartGame = async () => {
        if (!isEligibleToStart) {
            return;
        }

        if (game?.players?.length >= 1) {
            try {
                const response = await send({
                    route: "/start",
                    payload: { id: game.id },
                });

                if (response && response.payload.success) {
                    // Navegar a la página del juego, mostrar mensaje, etc.
                }
            } catch (error) {
                console.error("Error al iniciar la partida:", error);
            }
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        // Opcional: Muestra un mensaje de confirmación
    };

    const url = `https://www.plotploy.com/${lang}/game/${game.id}`; // URL del QR

    const isNameTaken = game?.players?.some((player) => player.name === newPlayerName);

    return (
        <div className="lobby-app-container">
            <p className="lobby-intro-text">
                {t("default-lobby")
                    .split("\n")
                    .map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
            </p>
            {!player && displayInputName && (
                <div className="invitation-input-container">
                    <input
                        type="text"
                        value={newPlayerName}
                        onChange={handleNewPlayerNameChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Tu nombre"
                    />
                    <button onClick={handleJoinGame} disabled={!newPlayerName || isNameTaken}>
                        {t("default-login")}
                    </button>
                    {isNameTaken && <p>Este nombre ya está en uso.</p>}
                </div>
            )}
            <div className="lobby-waiting-area">
                {player && (
                    <>
                        <div className="player-counter-box">
                            <span className="player-counter-text">
                                {t("default-lobby-counter", {
                                    count: game?.players?.length || 0,
                                })}
                            </span>
                        </div>
                        {game?.players?.length > 0 && (
                            <LobbyPlayersList
                                me={player}
                                isOwner={player && game?.ownerId === player?.id}
                                players={game?.players}
                                removePlayer={handleRemovePlayer}
                            />
                        )}
                    </>
                )}
                {player && game?.ownerId === player?.id && isEligibleToStart && (
                    <button
                        onClick={handleStartGame}
                        className={`start-lobby-button ${game?.players?.length < 1 ? "disabled" : ""}`}
                        disabled={game?.players?.length.length < 1}
                    >
                        {t("default-start-game")}
                    </button>
                )}
            </div>

            <div className="qr-container">
                <div className="game-id-display">
                    <h2>{game.id}</h2>
                </div>
                <QRCode
                    value={url}
                    size={256}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={true}
                    className="qr-code"
                />
                <p className="qr-url">{url}</p> {/* Texto con la URL */}
                <FaCopy onClick={copyToClipboard} className="copy-icon" />
            </div>
        </div>
    );
}

export default Lobby;
