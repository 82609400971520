import React from "react";
import "./OthersPortrait.css";
import "./RoleImages.css";

const OthersPortrait = ({ othersKnownInfo, winners }) => {
    // Función para generar estados aleatorios entre (dead, in_love, protected)
    const iconStates = ["in_love", "protected", "muted"];

    const alivePlayers = othersKnownInfo?.filter(
        (player) => !player?.states.includes("dead") && player?.role !== "spectator"
    );
    const deadPlayers = othersKnownInfo?.filter(
        (player) => player?.states.includes("dead") && player?.role !== "spectator"
    );
    const spectators = othersKnownInfo?.filter((player) => player?.role === "spectator");

    return (
        <div className="others-portrait-container">
            <div className="others-portrait-alive-list">
                {alivePlayers.map((player, index) => (
                    <div
                        key={index}
                        className={`other-portrait ${
                            winners?.some((winner) => winner.id === player.id) ? "winner" : ""
                        }`}
                    >
                        <div className={`other-image ${player?.role || "unknown"}`} alt={`Player ${index}`}></div>
                        <h3 className="other-name">{player?.name}</h3>
                        <div className="state-icons">
                            {player?.states
                                .filter((s) => iconStates.includes(s))
                                .map((state, idx) => (
                                    <div key={idx} className={`state-icon ${state}`}></div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="others-portrait-dead-list">
                {deadPlayers.map((player, index) => (
                    <div key={index} className="other-portrait">
                        <div className={`other-image ${player?.role || "dead"}`} alt={`Player ${index}`}></div>
                        <h3 className="other-name">{player?.name}</h3>
                        <div className="state-icons">
                            {player?.states
                                .filter((s) => iconStates.includes(s))
                                .map((state, idx) => (
                                    <div key={idx} className={`state-icon ${state}`}></div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="others-portrait-spectator-list">
                {spectators.map((player, index) => (
                    <div key={index} className="other-portrait">
                        <div className={`other-image spectator`} alt={`Player ${index}`}></div>
                        <h3 className="other-name">{player?.name}</h3>
                        <div className="state-icons">
                            {player?.states
                                .filter((s) => iconStates.includes(s))
                                .map((state, idx) => (
                                    <div key={idx} className={`state-icon ${state}`}></div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OthersPortrait;
