async function getAllowToExploreConfig(send, gameId) {
    try {
        const response = await send({
            route: "/getAllowToExploreConfig",
            payload: { gameId: gameId },
        });
        if (response && response.payload && response.payload.data && Object.keys(response.payload.data).length > 0) {
            return response.payload.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error al obtener el estado del juego:", error);
        // Manejo de errores
    }
}

async function explore(send, gameId, door) {
    try {
        const response = await send({
            route: "/explore",
            payload: { gameId: gameId, door: door },
        });
        if (response && response.payload && response.payload.data && Object.keys(response.payload.data).length > 0) {
            return response.payload.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error al obtener el estado del juego:", error);
        // Manejo de errores
    }
}

export default {
    getAllowToExploreConfig,
    explore,
};
