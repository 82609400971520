// gameService.js
async function getPending(send, gameId) {
    try {
        const response = await send({
            route: "/getPendingVoting",
            payload: { gameId: gameId },
        });
        if (
            response &&
            response.payload &&
            response.payload.data &&
            Object.keys(response.payload.data).length > 0
        ) {
            return response.payload.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error al obtener el estado del juego:", error);
        // Manejo de errores
    }
}

async function selectVote(send, payload) {
    try {
        const response = await send({
            route: "/votingSelection",
            payload,
        });
        return response; // Asegúrate de manejar la respuesta adecuadamente
    } catch (error) {
        console.error("Error al seleccionar la votación:", error);
    }
}

async function confirmVote(send, payload) {
    try {
        const response = await send({
            route: "/votingConfirmation",
            payload,
        });
        return response; // Asegúrate de manejar la respuesta adecuadamente
    } catch (error) {
        console.error("Error al confirmar la votación:", error);
    }
}

export default {
    getPending,
    selectVote,
    confirmVote,
};
