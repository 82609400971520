import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./GameApp.css"; // Asegúrate de actualizar los estilos en este archivo
import { useWebSocketContext } from "../WebSocketContext";
import gameService from "../services/game";
import playerService from "../services/player";
import votingService from "../services/voting";
import exploringService from "../services/exploring";
import ErrorPage from "./ErrorPage";
import { useTranslation } from "react-i18next";
import "./GameApp.css";
import "./ErrorPage.css";

import Lobby from "./Lobby";
import LobbySpectator from "./LobbySpectator";
import Play from "./Play";

function GameApp() {
    const { t } = useTranslation();

    const { gameId, lang } = useParams();
    // const navigate = useNavigate();

    const [game, setGame] = useState(null);
    const [player, setPlayer] = useState(null);
    const [othersKnownInfo, setOthersKnownInfo] = useState([]); // [{id, name}
    const [pendingVotings, setPendingVotings] = useState([]);
    const [allowToExploreConfig, setAllowToExploreConfig] = useState(null); // [{id, name}
    const [error, setError] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]); // [{type, numUses}]

    const { connected, on, send, sid } = useWebSocketContext();

    const handleResetGame = async () => {
        await gameService.resetGame(send, gameId);
    };

    const isSpectator = !player && game && game?.status !== "open" && true;

    const displayLobby = !isSpectator && game && game?.status === "open" && true;

    const displayGame = !isSpectator && game && game?.status !== "open" && true;

    const displaySpectatorLobby = isSpectator && game && game?.status !== "open" && true;

    useEffect(() => {
        if (connected) {
            // Suscribirse a las notificaciones de tipo 'playerAdded'
            on("newPlayer", ({ gameId, newPlayer, players }) => {
                setGame((currentGame) => {
                    if (currentGame && currentGame.id === gameId) {
                        return {
                            ...currentGame,
                            players: players,
                        };
                    } else {
                        return currentGame;
                    }
                });

                if (!player && sid === newPlayer.id) {
                    setPlayer({
                        id: newPlayer.id,
                        name: newPlayer.name,
                    });
                }
            });

            // Suscribirse a las notificaciones de tipo 'playerRemoved'
            on("removedPlayer", ({ gameId, playerId }) => {
                setGame((currentGame) => {
                    if (currentGame && currentGame.id === gameId) {
                        return {
                            ...currentGame,
                            players: currentGame?.players?.filter((p) => p.id !== playerId),
                        };
                    } else {
                        return currentGame;
                    }
                });
            });

            on("playerUpserted", ({ player }) => {
                setPlayer(player);
            });

            on("gameUpserted", (game) => {
                setGame(game);
            });
            on("newPhase", ({ phase }) => {
                setGame((game) => {
                    return {
                        ...game,
                        phase,
                    };
                });

                setPendingVotings([]);
            });
            on("newVoting", (pendingVoting) => {
                setPendingVotings((prevPending) => {
                    if (prevPending?.length > 0 && prevPending.find((v) => v.type === pendingVoting.type)) {
                        return prevPending.map((v) => {
                            if (v.type === pendingVoting.type) {
                                return pendingVoting;
                            } else {
                                return v;
                            }
                        });
                    } else {
                        return [...prevPending, pendingVoting];
                    }
                });
            });

            on("votingSelection", (pendingVoting) => {
                setPendingVotings((prevPending) => {
                    if (prevPending?.length > 0 && prevPending.find((v) => v.type === pendingVoting.type)) {
                        return prevPending.map((v) => {
                            if (v.type === pendingVoting.type) {
                                return pendingVoting;
                            } else {
                                return v;
                            }
                        });
                    }
                });
            });

            on("closeVoting", ({ votingType }) => {
                setPendingVotings((prevPending) => {
                    if (prevPending?.length > 0 && prevPending.find((v) => v.type === votingType)) {
                        return prevPending.filter((v) => v.type !== votingType);
                    }
                });
            });

            on("othersKnownInfoUpserted", (othersKnownInfo) => {
                setOthersKnownInfo((prevOhtersKnownInfo) => {
                    if (!prevOhtersKnownInfo) {
                        return [othersKnownInfo];
                    }
                    return [
                        ...prevOhtersKnownInfo.filter((other) => {
                            return other.id !== othersKnownInfo.id;
                        }),
                        othersKnownInfo,
                    ];
                });
            });

            on("allowToExplore", (allowToExplore) => {
                setAllowToExploreConfig(allowToExplore);
            });

            on("doorOpened", (doorOpened) => {
                setGame((currentGame) => {
                    return {
                        ...currentGame,
                        openDoors: [
                            ...(currentGame?.openDoors || []),
                            {
                                number: doorOpened.number,
                                treasureFound: doorOpened.treasureFound,
                            },
                        ],
                    };
                });
            });

            on("keyAmount", (doorOpened) => {
                setAllowToExploreConfig((currentAllowToExploreConfig) => {
                    return {
                        ...currentAllowToExploreConfig,
                        keys: doorOpened.keys,
                    };
                });
            });

            on("items", ({ items }) => {
                if (!items) {
                    return;
                }
                setItems(items?.filter((i) => i.numUses > 0));
            });

            on("reset", () => {
                window.location.reload();
            });
        }
    }, [connected, on, player, sid]);

    useEffect(() => {
        if (connected && !loaded) {
            const firstLoad = async () => {
                if (connected) {
                    const fetchAllowToExploreConfig = async (gameData) => {
                        if (gameData?.status !== "started") {
                            return;
                        }

                        const allowToExplore = await exploringService.getAllowToExploreConfig(send, gameId);

                        if (allowToExplore) {
                            setAllowToExploreConfig(allowToExplore);
                        } else {
                            setAllowToExploreConfig(null);
                        }
                    };

                    const fetchGame = async () => {
                        try {
                            const gameData = await gameService.getGame(send, gameId);
                            setGame(gameData);

                            const playerData = await playerService.getPlayer(send, gameId, sid);
                            if (playerData !== null) {
                                setPlayer(playerData.player);
                                setOthersKnownInfo(playerData.othersKnownInfo);
                                setItems(playerData.items);
                            } else if (playerData === null && gameData.status === "open") {
                                // Nothing to do
                            } else {
                                throw new Error("Player not found");
                            }
                            fetchAllowToExploreConfig(gameData);
                            fetchPendingVoting(gameData);
                        } catch (error) {
                            setError(error);
                        }
                    };

                    const fetchPendingVoting = async (gameData) => {
                        if (gameData?.status !== "started") {
                            return;
                        }
                        const pending = await votingService.getPending(send, gameId);
                        if (pending && pending?.pendingVotings.length > 0) {
                            setPendingVotings(pending?.pendingVotings || []);
                        }
                    };

                    try {
                        fetchGame();
                    } catch (error) {
                        console.error("Error al obtener jugadores:", error);
                    }
                }
            };

            firstLoad();
            setLoaded(true);
        }
    }, [connected, send, gameId, loaded, game?.status, sid]);

    if (error) {
        return <ErrorPage errorMessage={error.message} lang={lang} />;
    }

    return (
        <div className="game-app-container">
            {displayLobby && <Lobby game={game} player={player} lang={lang} send={send} sid={sid} />}
            {displaySpectatorLobby && <LobbySpectator game={game} player={player} lang={lang} send={send} sid={sid} />}
            {displayGame && (
                <Play
                    game={game}
                    player={player}
                    lang={lang}
                    send={send}
                    sid={sid}
                    pendingVotings={pendingVotings}
                    othersKnownInfo={othersKnownInfo}
                    allowToExploreConfig={allowToExploreConfig}
                    items={items}
                />
            )}
            {game && game.ownerId === player?.id && game.status !== "open" && (
                <button onClick={handleResetGame} className="reset-button">
                    {t("default-new-game")}
                </button>
            )}
        </div>
    );
}

export default GameApp;
