// gameService.js
async function getPlayer(send, gameId, sid) {
    const response = await send({
        route: "/player",
        payload: { id: sid, gameId: gameId },
    });
    if (
        response &&
        response.payload &&
        response.payload.success &&
        response.payload.data &&
        Object.keys(response.payload.data).length > 0
    ) {
        return response.payload.data;
    } else {
        return null;
    }
}

const playerService = {
    getPlayer,
};

export default playerService;
