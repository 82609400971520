import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import ErrorPage from "./components/ErrorPage";

const WebSocketContext = createContext(null);

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ url, children }) => {
    const [maxConnectionsReached, setMaxConnectionsReached] = useState(false);
    const [sid] = useState(() => {
        let savedSid = localStorage.getItem("sid");

        if (!savedSid) {
            savedSid = uuidv4();
            localStorage.setItem("sid", savedSid);
        }
        return savedSid;
    });

    const socket = useRef(null);
    const [connected, setConnected] = useState(false);
    const messageHandlers = useRef(new Map());

    const on = useCallback((type, handler) => {
        messageHandlers.current.set(type, handler);
    }, []);

    const waitForResponse = useCallback((id, timeout) => {
        return new Promise((resolve, reject) => {
            messageHandlers.current.set(id, resolve);

            setTimeout(() => {
                if (messageHandlers.current.has(id)) {
                    messageHandlers.current.delete(id);
                    reject(new Error("Tiempo de espera excedido"));
                }
            }, timeout);
        });
    }, []);

    const handleMessage = useCallback((event) => {
        const message = JSON.parse(event.data);

        if (message.route === "/ping") {
            if (message.payload.success === false && message.payload.error === "MAX_NUMBER_CONNECTIONS_REACHED") {
                setMaxConnectionsReached(true);
                if (socket.current) {
                    socket.current.close();
                }
                return;
            }
        }

        // Manejar notificaciones del servidor
        if (message.route === "notification" && message.type !== undefined) {
            const handler = messageHandlers.current.get(message.type);
            if (handler) {
                handler(message.payload);
            }
            return;
        }

        if (message.id !== undefined) {
            const resolve = messageHandlers.current.get(message.id);
            if (resolve) {
                resolve(message);
                messageHandlers.current.delete(message.id);
            }
        }
    }, []);

    const reconnectSocket = useCallback(() => {
        if (socket.current) {
            socket.current.close();
        }
        socket.current = new WebSocket(url);
        socket.current.onopen = () => {
            setConnected(true);
            socket.current.send(JSON.stringify({ route: "/ping", sid }));
        };

        // Enviar un ping cada 5 segundos
        const pingInterval = setInterval(() => {
            if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                socket.current.send(JSON.stringify({ route: "/ping", sid }));
            }
        }, 5000);

        socket.current.onmessage = handleMessage;
        socket.current.onclose = () => {
            clearInterval(pingInterval);
            setConnected(false);
            setTimeout(reconnectSocket, 3000); // Intentar reconectar después de 3 segundos
        };
        socket.current.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };
    }, [url, handleMessage, sid]);

    useEffect(() => {
        reconnectSocket(); // Establecer la conexión inicial

        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, [reconnectSocket]);

    const send = useCallback(
        (message) => {
            const id = uuidv4();

            if (socket.current && connected) {
                socket.current.send(JSON.stringify({ id, sid, ...message }));
                return waitForResponse(id, 10000); // 30 segundos de timeout
            }
            return Promise.reject(new Error("WebSocket no conectado"));
        },
        [connected, sid, waitForResponse]
    );

    if (maxConnectionsReached) {
        return <ErrorPage />;
    }

    return <WebSocketContext.Provider value={{ connected, send, on, sid }}>{children}</WebSocketContext.Provider>;
};
