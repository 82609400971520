import React, { useState, useEffect } from "react";
import "./Voting.css";
import votingService from "../services/voting";
import { useTranslation } from "react-i18next";

function Voting({ selectedVoting, pendingVotings, handleBackToVotings, game, player, lang, send, sid }) {
    const { t } = useTranslation();
    const [selectedPlayers, setSelectedPlayers] = useState(null);

    const voting = pendingVotings?.find((p) => p.type === selectedVoting);

    const handlePlayerSelect = (playerId) => {
        setSelectedPlayers((prevSelected) => {
            if (prevSelected === null) prevSelected = [];

            const isSelected = prevSelected.includes(playerId);
            if (isSelected) {
                return prevSelected.filter((id) => id !== playerId);
            } else {
                if (prevSelected.length < voting.maxNumVotes) {
                    return [...prevSelected, playerId];
                }
                return [...prevSelected.slice(1), playerId];
            }
        });
    };

    useEffect(() => {
        if (!voting) return;
        if (voting.targets[""]) {
            setSelectedPlayers([player?.id]);
        }
    }, [voting, player?.id]);

    useEffect(() => {
        if (!selectedPlayers) return;
        if (!voting?.type) return;
        votingService.selectVote(send, {
            gameId: game.id,
            type: voting.type,
            targets: selectedPlayers,
        });
    }, [selectedPlayers, send, game.id, voting?.type]);

    const handleConfirmVote = () => {
        if (!selectedPlayers || selectedPlayers.length !== voting?.maxNumVotes || !voting?.type) return;
        votingService.confirmVote(send, {
            gameId: game.id,
            type: voting.type,
            targets: selectedPlayers,
        });
        handleBackToVotings();
    };

    // Función para manejar el cierre y enviar una votación vacía
    const handleClose = () => {
        if (!voting?.type) return;
        votingService.selectVote(send, {
            gameId: game.id,
            type: voting.type,
            targets: [],
        });

        // Luego regresa al componente anterior
        handleBackToVotings();
    };

    return (
        <>
            <div className="voting-container">
                <button className="close-button" onClick={handleClose}>
                    &#10005;
                </button>
                <div className="voting-header">
                    <h2>{t(`default-votation-title-${selectedVoting}`)}</h2>
                    <p>{t(`default-votation-description-${selectedVoting}`)}</p>
                </div>
                {(voting?.targets || []).map((target) => (
                    <div className="voting-player-option" key={target.playerId}>
                        <button
                            className={`voting-player-button ${
                                selectedPlayers?.includes(target.playerId) ? "selected" : ""
                            } ${target.allowed ? "achive" : "inactive"}`}
                            onClick={() => handlePlayerSelect(target.playerId)}
                            disabled={!target.allowed}
                        >
                            {target.name}
                            {voting.displayToOthers && <div className="vote-count">{target?.numPossibleVotes}</div>}
                        </button>
                    </div>
                ))}
                <div className="confirm-button-container">
                    <button
                        className={`confirm-button ${
                            selectedPlayers && selectedPlayers.length === voting.maxNumVotes ? "active" : "inactive"
                        }`}
                        onClick={handleConfirmVote}
                        disabled={!selectedPlayers || selectedPlayers.length !== voting.maxNumVotes}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </>
    );
}

export default Voting;
