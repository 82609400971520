import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Lobby.css";

function LobbySpectator({ game, player, lang, send, sid, isSpectator }) {
    const { t } = useTranslation();

    const [newPlayerName, setNewPlayerName] = useState("");
    const [displayInputName, setDisplayInputName] = useState(true);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleJoinGame();
        }
    };
    const handleNewPlayerNameChange = (e) => {
        setNewPlayerName(e.target.value);
    };

    const handleJoinGame = async () => {
        try {
            const response = await send({
                route: "/addSpectator",
                payload: {
                    gameId: game.id,
                    name: newPlayerName,
                },
            });

            if (response && response.payload.success) {
                setDisplayInputName(false);
            }
        } catch (error) {
            console.error("Error al unirse a la partida:", error);
        }
    };

    const containerStyle = {
        justifyContent: "center",
    };

    return (
        <div className="lobby-app-container" style={containerStyle}>
            <p className="lobby-intro-text">
                {"Esta partida está en curso. Puedes unirte como espectador".split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </p>
            {!player && displayInputName && (
                <div className="invitation-input-container">
                    <input
                        type="text"
                        onChange={handleNewPlayerNameChange}
                        value={newPlayerName}
                        onKeyDown={handleKeyDown}
                        placeholder="Tu nombre"
                    />
                    <button onClick={handleJoinGame}>Espectar</button>
                </div>
            )}
        </div>
    );
}

export default LobbySpectator;
