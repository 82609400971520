async function movePhase(send, gameId) {
    const response = await send({
        route: "/movePhaseProposal",
        payload: { gameId },
    });
    if (response && response.payload && response.payload.success) {
        return true;
    } else {
        return false;
    }
}

const phaseService = {
    movePhase,
};

export default phaseService;
