import React from "react";
import "./LearnToPlay.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import phaseCheersImage from "../assets/images/phaseCheers.webp";
import phaseSocialImage from "../assets/images/phaseSocial.webp";
import phaseTrialImage from "../assets/images/phaseTrial.webp";

import roleAristocratImage from "../assets/images/aristocrat.webp";
import roleVampireImage from "../assets/images/vampire.webp";
import roleDraculaImage from "../assets/images/dracula.webp";
import roleCensorImage from "../assets/images/censor.webp";
import rolePriestImage from "../assets/images/priest.webp";
import roleMediumImage from "../assets/images/medium.webp";
import roleMatchmakerImage from "../assets/images/matchmaker.webp";

import stateInLoveImage from "../assets/icons/inLove.webp";
import stateMutedImage from "../assets/icons/muted.webp";
import stateProtectedImage from "../assets/icons/protected.webp";

import itemCrucifixImage from "../assets/icons/crucifix.webp";
import itemMaskImage from "../assets/icons/mask.webp";
import itemSpeakerImage from "../assets/icons/speaker.webp";
import itemRoyalPardonImage from "../assets/icons/royalPardon.webp";
import itemPhilosophersStoneImage from "../assets/icons/philosophersStone.webp";

const CharacterCard = ({ image, title, description }) => (
    <div className="character-card">
        <img src={image} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
);

const PhaseCard = ({ image, title, description }) => (
    <div className="phase-card">
        <img src={image} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
);

const StateCard = ({ image, title, description }) => (
    <div className="state-card">
        <img src={image} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
);

const ItemCard = ({ image, title, description }) => (
    <div className="item-card">
        <img src={image} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
);

const LearnToPlayButton = () => {
    const navigate = useNavigate();
    const { lang } = useParams();
    const { t } = useTranslation();

    return (
        <button onClick={() => navigate(`/${lang}`)} className="learn-to-play-button">
            {t("default-lets-play")}
        </button>
    );
};

const LearnToPlay = () => {
    const { t } = useTranslation();
    const { lang } = useParams();

    const renderTextWithLineBreaks = (text) => {
        return text.split("\n").map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="learn-to-play-container">
            <section id="how-to-play">
                <h2>{t("default-plotploy-title")}</h2>
                <p>{t("default-plotploy-description")}</p>
            </section>

            <section id="plotploy-explanation">
                <h2>{t("default-plotploy-explanation-title")}</h2>
                <p>{renderTextWithLineBreaks(t("default-plotploy-explanation-description"))}</p>
            </section>

            <section id="how-to-play">
                <h2>{t("default-phases-title")}</h2>
                <div className="phase-grid">
                    <PhaseCard
                        image={phaseCheersImage}
                        title={t("default-phase-cheers-title")}
                        description={t("default-phase-cheers-description")}
                    />
                    <PhaseCard
                        image={phaseSocialImage}
                        title={t("default-phase-social-title")}
                        description={t("default-phase-social-description")}
                    />
                    <PhaseCard
                        image={phaseTrialImage}
                        title={t("default-phase-trial-title")}
                        description={t("default-phase-trial-description")}
                    />
                    {/* Repite para otras fases */}
                </div>
            </section>

            <section id="characters">
                <h2>{t("default-characters-title")}</h2>
                <div className="character-grid">
                    {/* Ejemplo de un personaje */}

                    <CharacterCard
                        image={roleAristocratImage}
                        title={t("default-role-aristocrat-title")}
                        description={t("default-role-aristocrat-description")}
                    />
                    <CharacterCard
                        image={roleVampireImage}
                        title={t("default-role-vampire-title")}
                        description={t("default-role-vampire-description")}
                    />
                    <CharacterCard
                        image={roleMediumImage}
                        title={t("default-role-medium-title")}
                        description={t("default-role-medium-description")}
                    />
                    <CharacterCard
                        image={rolePriestImage}
                        title={t("default-role-priest-title")}
                        description={t("default-role-priest-description")}
                    />
                    <CharacterCard
                        image={roleMatchmakerImage}
                        title={t("default-role-matchmaker-title")}
                        description={t("default-role-matchmaker-description")}
                    />
                    <CharacterCard
                        image={roleCensorImage}
                        title={t("default-role-censor-title")}
                        description={t("default-role-censor-description")}
                    />
                    <CharacterCard
                        image={roleDraculaImage}
                        title={t("default-role-dracula-title")}
                        description={t("default-role-dracula-description")}
                    />
                </div>
                {/* Repetir para cada personaje */}
            </section>

            <section id="states">
                <h2>{t("default-states-title")}</h2>
                <div className="state-grid">
                    <StateCard
                        image={stateInLoveImage}
                        title={t("default-state-inLove-title")}
                        description={t("default-state-inLove-description")}
                    />
                    <StateCard
                        image={stateProtectedImage}
                        title={t("default-state-protected-title")}
                        description={t("default-state-protected-description")}
                    />
                    <StateCard
                        image={stateMutedImage}
                        title={t("default-state-muted-title")}
                        description={t("default-state-muted-description")}
                    />
                    {/* Repite para otras fases */}
                </div>
            </section>

            <section id="items">
                <h2>{t("default-items-title")}</h2>
                <div className="item-grid">
                    <ItemCard
                        image={itemCrucifixImage}
                        title={t("default-item-crucifix-title")}
                        description={t("default-item-crucifix-description")}
                    />
                    <ItemCard
                        image={itemRoyalPardonImage}
                        title={t("default-item-royalPardon-title")}
                        description={t("default-item-royalPardon-description")}
                    />
                    <ItemCard
                        image={itemMaskImage}
                        title={t("default-item-mask-title")}
                        description={t("default-item-mask-description")}
                    />
                    <ItemCard
                        image={itemSpeakerImage}
                        title={t("default-item-speaker-title")}
                        description={t("default-item-speaker-description")}
                    />
                    <ItemCard
                        image={itemPhilosophersStoneImage}
                        title={t("default-item-philosophersStone-title")}
                        description={t("default-item-philosophersStone-description")}
                    />
                    {/* Repite para otras fases */}
                </div>
            </section>
            <div className="bottom-section">
                <LearnToPlayButton lang={lang} />
            </div>
        </div>
    );
};

export default LearnToPlay;
