import React, { useState } from "react";
import "./CreateApp.css"; // Asegúrate de actualizar los estilos en este archivo
import { useWebSocketContext } from "../WebSocketContext";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

function CreateApp() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { lang } = useParams();

    const [playerName, setPlayerName] = useState("");
    const [invitationCode, setInvitationCode] = useState("");

    const { send, sid } = useWebSocketContext();

    const lengthCode = 5;

    //TODO: make a unique solution if this scale up at any point
    const generateGameID = () => {
        return Math.random().toString(36).toUpperCase().slice(-lengthCode);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleCreateGame();
        }
    };

    const handleInvitationCodeChange = (e) => {
        const code = e.target.value.toUpperCase().slice(0, 5);
        setInvitationCode(code);
    };

    const handleKeyDownCode = (e) => {
        if (e.key === "Enter" && invitationCode.length === 5) {
            handleSubmitInvitation();
        }
    };

    const handleSubmitInvitation = () => {
        navigate(`/${lang}/game/${invitationCode}`);
    };

    const handlePlayerNameChange = (e) => {
        setPlayerName(e.target.value);
    };

    const handleCreateGame = async () => {
        try {
            const gameId = generateGameID();
            const response = await send({
                route: "/create",
                payload: {
                    gameId: gameId,
                    playerId: sid,
                    name: playerName,
                },
            });

            if (response.payload.success) {
                navigate(`/${lang}/game/${gameId}`);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleCreateGameClick = () => {
        handleCreateGame();
    };

    const LearnToPlayButton = () => (
        <button onClick={() => navigate(`/${lang}/learn-to-play`)} className="learn-to-play-button">
            {t("default-learn-to-play")}
        </button>
    );

    return (
        <div className="create-app-container">
            <div className="top-section">
                <p className="intro-text">{t("default-intro")}</p>
            </div>
            <div className="input-sections">
                <div className="invitation-input-container">
                    <input
                        type="text"
                        placeholder="Código"
                        value={invitationCode}
                        onChange={handleInvitationCodeChange}
                        onKeyDown={handleKeyDownCode}
                        className={invitationCode.length === lengthCode ? "full-code" : "incomplete-code"}
                    />
                    <button
                        onClick={handleSubmitInvitation}
                        className="invitation-button"
                        disabled={invitationCode.length !== lengthCode}
                    >
                        {t("default-login")}
                    </button>
                </div>

                <div className="create-input-container">
                    <input
                        type="text"
                        placeholder={t("default-player-name")}
                        value={playerName}
                        onChange={handlePlayerNameChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button onClick={handleCreateGameClick} className="invitation-button" disabled={!playerName}>
                        {t("default-create-game")}
                    </button>
                </div>
            </div>
            <div className="bottom-section">
                <LearnToPlayButton />
            </div>
        </div>
    );
}

export default CreateApp;
