// App.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateApp from "./components/CreateApp";
import GameApp from "./components/GameApp";
import LearnToPlay from "./components/LearnToPlay";
import "./App.css";
import { WebSocketProvider } from "./WebSocketContext";
import LanguageWrapper from "./components/LanguageWrapper";

function App() {
    const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080";

    return (
        <WebSocketProvider url={wsUrl}>
            <Routes>
                <Route
                    path="/:lang"
                    element={
                        <LanguageWrapper>
                            <CreateApp />
                        </LanguageWrapper>
                    }
                />
                <Route
                    path="/:lang/game/:gameId"
                    element={
                        <LanguageWrapper>
                            <GameApp />
                        </LanguageWrapper>
                    }
                />
                <Route
                    path="/:lang/learn-to-play"
                    element={
                        <LanguageWrapper>
                            <LearnToPlay />
                        </LanguageWrapper>
                    }
                />
            </Routes>
        </WebSocketProvider>
    );
}

export default App;
